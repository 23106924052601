<!-- 首页-应用中心 -->
<template>
    <hm-card class="home-apps">
        <div class="home-apps-top">
            <hm-title title="应用中心"></hm-title>
            <!-- <div class="search-input-wrap">
                <el-input
                    v-model="filterText"
                    class="search-input"
                    placeholder="搜索应用名称"
                />
                <i
                    class="el-icon-search"
                    @click="searchMenu"
                />
            </div> -->
            <div class="global-search-wrapper">
                <i
                    class="el-icon-search"
                    @click="searchMenu"
                />
                <input
                    class="global-search"
                    v-model="filterText"
                    placeholder="搜索应用名称"
                    type="text"
                    autocomplete="off"
                />
            </div>
        </div>
        <div
            v-loading="loading"
            class="loading-wrap"
        >
            <div class="header-menu">
                <hm-button
                    v-for="(item, index) in headerMenu"
                    :key="`button-${item.menuId}`"
                    :type="active === index ? 'active' : 'newDefault'"
                    @click="getSubMenuList(item, index)"
                >
                    <span v-if="item.title.length <= 4">{{ item.title }}</span>
                    <el-tooltip v-else :content="item.title">
                        <span style="white-space: nowrap">{{ item.title.slice(0, 4) }}...</span>
                    </el-tooltip>
                </hm-button>
            </div>
            <!-- <el-collapse
                v-if="subMenuList.length > 0"
                :value="subActive"
                @change="handleCollapseChange"
            >
                <el-collapse-item
                    v-for="collapse in subMenuList"
                    :name="collapse.menuId"
                    :key="`collapse-${collapse.menuId}`"
                >
                    <template #title>
                        <i class="el-icon-caret-right"></i>
                        <span class="el-collapse__header__title">
                            {{ collapse.title }}
                        </span>
                    </template>
                    <div class="menu-wrap">
                        <hm-menu-item
                            v-for="item in collapse.children"
                            :data="item"
                            :key="item.menuId"
                        />
                    </div>
                </el-collapse-item>
            </el-collapse> -->
            <template v-if="subMenuList.length > 0">
                <!-- <div v-for="collapse in subMenuList"> -->
                    <div class="menu-wrap">
                        <hm-menu-item
                            v-for="item in subMenuList"
                            :data="item"
                            :key="item.menuId"
                        />
                    </div>
                <!-- </div> -->
            </template>
            <el-empty v-else-if="!loading" style="padding: 47px 0;"></el-empty>
        </div>
    </hm-card>
</template>

<script>
import HmCard from "./Sub/hm-card.vue";
import HmTitle from "./Sub/hm-title.vue";
import HmMenuItem from "./Sub/hm-menu-item.vue";
import HmButton from "./Sub/hm-button.vue";
import { treeSearch } from "@/libs/utils";
import TextTooltip from "@/components/scrollWrapper/Sub/TableData/TextTooltip.vue";

export default {
    name: "HomeApplications",
    components: {
        TextTooltip,
        HmButton,
        HmMenuItem,
        HmTitle,
        HmCard,
    },
    data() {
        return {
            loading: true,
            active: 0,
            menuList: [],
            filterMenuList: [],
            filterAllMenuList: [],
            subMenuList: [],
            filterText: "",
        };
    },
    computed: {
        headerMenu() {
            if (this.filterText) {
                return this.filterMenuList;
            } else {
                return this.menuList;
            }
        },
        subActive() {
            let arr = [];
            this.subMenuList.forEach((item) => {
                if (item.expanded) {
                    arr.push(item.menuId);
                }
            });
            return arr;
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this._fet("/school/schoolUserCommon/getAllAuthMenuBuUser")
                .then((res) => {
                    if (res.data.code === "200" && res.data.data.length > 0) {
                        res.data.data.forEach((item) => {
                            item.children.forEach((it) => {
                                // 默认展开全部子菜单
                                this.$set(it, "expanded", true);
                                // 不存在子菜单显示当前菜单
                                if (it.children && it.children.length === 0) {
                                    let data = this._.cloneDeep(it)
                                    delete it.children
                                    it.children = [data];
                                }
                            });
                        });
                        this.filterAllMenuList = this._.cloneDeep(res.data.data)
                        this.menuList = this._.cloneDeep(res.data.data);
                        this.getSubMenuList(this.menuList[0]);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        searchMenu() {
            if (this.filterText) {
                this.filterMenuList = treeSearch(
                    this.filterAllMenuList,
                    "children",
                    (x) => x.title.indexOf(this.filterText) > -1,
                    true
                );
                this.getSubMenuList(this.filterMenuList[0]);
            } else {
                this.filterMenuList = [];
                this.getSubMenuList(this.menuList[0]);
            }
        },
        getSubMenuList(headerMenu, index = 0) {
            this.active = index;
            if (
                headerMenu &&
                headerMenu.children &&
                headerMenu.children.length > 0
            ) {
                this.subMenuList = headerMenu.children;
            } else {
                this.subMenuList = [];
            }
            console.log(this.subMenuList,'this.subMenuList')
        },
        handleCollapseChange(data) {
            // 修改当前菜单展开收起状态
            this.subMenuList.forEach((item) => {
                item.expanded = data.includes(item.menuId);
            });
        },
    },
    watch: {
        filterText() {
            this.searchMenu();
        },
    },
};
</script>

<style scoped lang="scss">
.home-apps {
    background-color: #F1F1F1;
    padding: 16px 0 0 0;
    .home-apps-top {
        display: flex;
        align-items: center;
        padding: 0px 20px ;

        .search-input-wrap {
            position: relative;
            .search-input {
                margin-left: 17px;
                width: 142px;
                border-radius: 15px;
                transition: width .2s ease-in;

                &:focus-within {
                    width: 202px;
                }

                ::v-deep .el-input__inner {
                    height: 30px;
                    color: #333333;
                    background: #fafafa;
                    border-radius: 15px;
                    padding-right: 34px;

                    &:focus {
                        border-color: #2166ee;
                    }

                    &::placeholder {
                        color: #999999;
                        font-size: 12px;
                    }
                }
            }
            .el-icon-search {
                position: absolute;
                right: -16px;
                cursor: pointer;
                width: 40px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                z-index: 99;
                font-size: 13px;
                background: #2166ee;
                color: #ffffff;
                border-radius: 15px;
            }
        }




    }

    .loading-wrap {
        // min-height: 300px;
        // background-color: #ffffff;
        // max-height: 300px;
        // overflow-y: auto;
    }

    .header-menu {
        margin-top: 11px;
        display: flex;
        padding: 0 20px;
        overflow-y: auto;
        &::-webkit-scrollbar{
            height: 6px !important;
        }
    }

    .menu-wrap {
        display: flex;
        // align-items: center;
        flex-wrap: wrap;
        background-color: #ffffff;
        padding-top: 10px;
        // min-height: 300px;
        max-height: 299px;
        height: 299px;
        overflow-y: auto;
    }

    ::v-deep .el-collapse {
        margin-top: 14px;
        border: 0;
        background-color: #ffffff;

        &-item {
            &__header {
                height: 20px;
                line-height: 20px;
                margin-bottom: 12px;
                border: 0;
                color: #333333;
            }

            &__arrow {
                display: none;
            }

            &__content {
                padding-bottom: 20px;
            }

            &__wrap {
                border: 0;
                overflow: unset;
            }
        }

        .el-icon-caret-right {
            transition: all 0.3s linear;
            color: #2166ee;
            margin-right: 3px;
        }

        .is-active .el-icon-caret-right {
            transform: rotate(90deg);
        }
    }
    .global-search-wrapper {
            color: #fff;
            width: auto;
            border-radius: 18px;
            min-width: 30px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            position: relative;
            overflow: hidden;
            background-size: 104% 104%;
            cursor: pointer;
            margin-right: 20px;
            margin-left: 20px;
        .el-icon-search {
            position: absolute;
            right: 0px;
            top: 0;
            width: 40px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 13px;
            background: #2166ee;
            color: #ffffff;
            border-radius: 15px;
            cursor: pointer;
            z-index: 99;


        }
        .global-search {
            display: inline-block;
            background: 0 0;
            border-radius: 18px;
            border: none;
            padding-left: 12px;
            line-height: 30px !important;
            height: 30px;
            box-sizing: border-box;
            vertical-align: 4px;
            font-size: 13px;
            width: 36px;
            color: #333333;
            transition: all .3s ease-in-out;
        }
        input {
            padding-right: 40px;
        }
        input::placeholder {
            color: #999999;
            font-size: 12px;
        }
        input:focus {
            display: inline-block;
            width: 140px;
            background: #FFFFFF;
            outline: none;
        }
    }
    .global-search-wrapper:hover {
        .global-search {
            display: inline-block;
            width: 140px;
            background: #FFFFFF;
        }
    }
}
</style>
