<template>
    <div class="add-menu-dialog">
        <div class="used-apps">
            <span class="title">常用应用</span>
            <span class="sub-title">拖动调整顺序</span>
            <draggable
                v-if="usedList.length > 0"
                v-model="usedList"
                group="userList"
                :animation="300"
                @start="drag = true;"
                @end="drag = false"
            >
                <transition-group :name="!drag ? 'move' : ''" class="sub-menu-wrap">
                    <div
                        v-for="(item, index) in usedList"
                        class="hm-menu-item"
                        :key="`draggable-${item.menuId}`"
                    >
                        <hm-menu-icon
                            :url="item.icon"
                            :name="item.name"
                        />
                        <span class="hm-menu-item__name">
                            <text-tooltip :content="getTitle(item)" placement="bottom"></text-tooltip>
                        </span>
                        <i
                            class="operate-icon el-icon-close"
                            @click.stop="handleDelete(item, index)"
                        />
                    </div>
                </transition-group>
            </draggable>
            <div
                v-else
                class="used-apps__empty"
            >
                暂无数据
            </div>
        </div>
        <div class="all-apps-title">
            <span class="title">全部应用</span>
            <div class="search-input-wrap">
                <el-input
                    v-model="filterText"
                    class="search-input"
                    placeholder="搜索应用名称"
                />
                <i class="el-icon-search" />
            </div>
        </div>
        <div class="all-apps-container">
            <div class="header-menu-wrap">
                <hm-button
                    v-for="(item, index) in allList"
                    :key="`button-${item.menuId}`"
                    :type="active === index ? 'primary' : 'default'"
                    @click="active = index"
                >
                    <span v-if="item.title.length <= 4">{{ item.title }}</span>
                    <el-tooltip v-else :content="item.title">
                        <span style="white-space: nowrap">{{ item.title.slice(0, 4) }}...</span>
                    </el-tooltip>
                </hm-button>
            </div>
            <el-collapse v-if="allList[active] && allList[active].children.length > 0" :value="subActive">
                <!-- <el-collapse-item
                    v-for="collapse in allList[active].children"
                    :name="collapse.menuId"
                    :title="`${collapse.title}:`"
                    :disabled="true"
                    :key="`collapse-${collapse.menuId}`"
                > -->
                    <div class="sub-menu-wrap">
                        <div
                            v-for="item in allList[active].children"
                            class="hm-menu-item"
                            :key="item.menuId"
                        >
                            <hm-menu-icon
                                :url="item.icon"
                                :name="item.name"
                            />
                            <span class="hm-menu-item__name">
                                <text-tooltip :content="getTitle(item)" placement="bottom"></text-tooltip>
                            </span>
                            <i
                                v-if="!usedList.map((i) => i.menuId).includes(item.menuId)"
                                class="operate-icon el-icon-plus"
                                @click="handleAdd(item)"
                            />
                        </div>
                    </div>
                <!-- </el-collapse-item> -->
            </el-collapse>
            <el-empty v-else></el-empty>
        </div>
        <div style="text-align: center; margin-top: 29px">
            <el-button
                :loading="loading"
                type="primary"
                @click="handleSubmit"
            >确认
            </el-button>
            <el-button @click="handleClose">取消</el-button>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import HmMenuItem from "../Sub/hm-menu-item.vue";
import HmMenuIcon from "../Sub/hm-menu-icon.vue";
import HmButton from "../Sub/hm-button.vue";
import { treeSearch } from "@/libs/utils";
import TextTooltip from "@/components/scrollWrapper/Sub/TableData/TextTooltip.vue";

export default {
    name: "DialogData",
    props: {
        loading: {
            type: Boolean
        },
        usedMenuList: {
            type: Array,
        },
        allMenuList: {
            type: Array,
        },
    },
    components: {
        TextTooltip,
        draggable,
        HmButton,
        HmMenuIcon,
        HmMenuItem,
    },
    data() {
        return {
            drag: false,
            disabledDrag: true,
            active: 0,
            filterText: "",
            usedList: [],
            allList: [],
        };
    },
    computed: {
        subActive() {
            return this.allList[this.active].children.map((i) => i.menuId);
        },
    },
    created() {
        this.usedList = this._.cloneDeep(this.usedMenuList);
        this.allList = this._.cloneDeep(this.allMenuList);
        console.log(this.allList,'this.allList')
    },
    methods: {
        handleDelete(item, index) {
            this.disabledDrag = true
            this.usedList.splice(index, 1);
            setTimeout(() => {
                this.disabledDrag = false
            }, 300)
        },
        handleAdd(item) {
            this.usedList.push(item);
        },
        handleSubmit() {
            this.$emit("submit", this.usedList);
        },
        handleClose() {
            this.$emit("close");
        },
        getTitle(data) {
            return data.title || data.menuName
        },
    },
    watch: {
        filterText() {
            this.active = 0;
            if (this.filterText) {
                this.allList = treeSearch(
                    this.allList,
                    "children",
                    (x) => x.title.indexOf(this.filterText) > -1,
                    true,
                );
            } else {
                this.allList = this.allMenuList;
            }
        }
    },
};
</script>

<style scoped lang="scss">
.title {
    line-height: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
}

.sub-title {
    line-height: 12px;
    font-size: 14px;
    color: #999999;
    margin-left: 11px;
}

.used-apps {
    min-height: 142px;

    &__empty {
        margin-top: 50px;
        text-align: center;
        color: #999999;
    }
}

.all-apps-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
        margin-top: 5px;
    }

    .search-input-wrap {
        position: relative;
    }

    .search-input {
        margin-left: 17px;
        width: 142px;
        border-radius: 15px;
        transition: width .2s ease-in;
        padding-right: 16px;

        &:focus-within {
            width: 202px;
        }

        ::v-deep .el-input__inner {
            height: 30px;
            color: #333333;
            background: #fafafa;
            border-radius: 15px;
            padding-right: 34px;

            &:focus {
                border-color: #2166ee;
            }

            &::placeholder {
                color: #999999;
                font-size: 12px;
            }
        }
    }

    .el-icon-search {
        position: absolute;
        right: 0;
        cursor: pointer;
        width: 40px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        z-index: 2;
        font-size: 13px;
        background: #2166ee;
        color: #ffffff;
        border-radius: 15px;
    }
}

.all-apps-container {
    height: 350px;
    margin-top: 11px;
    overflow-y: auto;

    .header-menu-wrap {
        display: flex;
        overflow-x: auto;
        &::-webkit-scrollbar{
            height: 6px;
        }
    }
}

.sub-menu-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 24px 18px 0 4px;

    .hm-menu-item {
        position: relative;
        width: calc(100% / 7);
        margin-bottom: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__name {
            width: 100%;
            margin-top: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
        }

        .operate-icon {
            cursor: pointer;
            position: absolute;
            top: -8px;
            right: 18px;
            width: 15px;
            height: 15px;
            line-height: 15px;
            text-align: center;
            border-radius: 50%;
            color: #ffffff;
            font-weight: bold;
            font-size: 12px;
            border: 3px solid #ffffff;

            &::after {
                content: "";
                position: absolute;
                top: -10px;
                right: -10px;
                bottom: -10px;
                left: -10px;
            }
        }

        .el-icon-close {
            background-color: #e53b3b;
        }

        .el-icon-plus {
            background-color: #2166ee;
        }
    }
}

::v-deep .el-collapse {
    margin-top: 11px;
    border: 0;

    &-item {
        &__header {
            height: 20px;
            line-height: 20px;
            border: 0;
            color: #666666 !important;
            cursor: default !important;
            font-size: 14px;
        }

        &__arrow {
            display: none;
        }

        &__content {
            padding-bottom: 4px;
        }

        &__wrap {
            border: 0;
        }
    }
}

//.move-move {
//    transition: all 0.3s ease;
//}
//
//.move-leave-active {
//    position: absolute !important;
//    opacity: 0;
//}
//
//.move-enter-active {
//    opacity: 0;
//    transition: all 0.3s ease;
//}
//
//.move-enter-to {
//    opacity: 1;
//}
</style>
