<template>
    <div>
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="tabs-list">
                    <div
                        :class="['tabs-item',{ current: listQuery.queryState === '1' }]"
                        @click="tabClick('1')"
                    >待我审批</div>
                    <div
                        :class="['tabs-item',{ current: listQuery.queryState === '2' }]"
                        @click="tabClick('2')"
                    >我已审批</div>
                </div>
                <div class="content-main">
                    <expand-filter :formData="formData" @clickBtn="clickBtn" @changeBtnFormType="changeBtnFormType"></expand-filter>
                    <!-- 表格 -->
                    <table-data
                        ref="table"
                        :config="table_config"
                        :tableData="table_data"
                    >
                        <template v-slot:operation="slotData">
                            <el-button type="text" @click="formDetail(slotData.data)">详情</el-button>
                        </template>
                    </table-data>
                    <Pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList"
                    />
                </div>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    Pagination,
    // 功能组件
    Loading,
    Error
} from 'common-local';
// 逻辑运算
import {
    mapMutations,
    mapState
} from 'vuex';
import {CampusOAModel} from "@/models/CampusOA";
import onResize from "@/mixins/onResize";
import ExpandFilter from "./Sub/ExpandFilter";
import TableData from "@/components/scrollWrapper/Sub/TableData"

export default {
    name: "DealtList",
    mixins: [onResize],
    components: {
        Pagination,
        Loading,
        Error,
        TableData,
        ExpandFilter
    },
    data() {
        return {
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            formData: {
                data: [
                    {
                        type: 'datePick',
                        label: '',
                        value: '',
                        placeholder: '发起日期',
                        key: 'time',
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '标题名称',
                        key: 'dataTitle',
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '状态',
                        key: 'approveStats',
                        list: [
                            {label: '审批中', value: '1'},
                            {label: '审批通过', value: '2'},
                            {label: '审批拒绝', value: '3'},
                            {label: '已撤销', value: '4'},
                            {label: '审批通过(撤销拒绝)', value: '5'},
                        ]
                    }
                ],
                btnList: [{
                    type: "primary",
                    text: '查询',
                    fn: 'primary'
                }, {
                    type: "enquiry",
                    text: '重置',
                    fn: 'reset'
                }],
                btnFormType: true,
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "表单标题",
                        prop: "dataTitle",
                        labelWidth: '260px'
                    },
                    {
                        label: "摘要",
                        prop: "dataAbstract",
                        type: 'function',
                        labelWidth:'250px',
                        align: 'left',
                        callBack(row) {
                            let data = []
                            let html = ''
                            if (row.dataAbstract && row.dataAbstract.includes('、')) {
                                data = row.dataAbstract.split('、')
                                data.forEach((item) => {
                                    html += `<div>${item}</div>`
                                })
                                return html
                            } else {
                                return row.dataAbstract
                            }
                        }
                    },
                    {
                        label: "发起人",
                        prop: "applyName",
                        labelWidth:'200px',
                    },
                    {
                        label: "发起时间",
                        prop: "createTime",
                        labelWidth: '200px',
                        align: "left",
                        type: "function",
                        callBack: (row) => {
                            if (row.createTime) {
                                return row.createTime.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "完成时间",
                        prop: "endTime",
                        labelWidth: '200px',
                        align: "left",
                        type: "function",
                        callBack: (row) => {
                            if (row.endTime) {
                                return row.endTime.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "流程状态",
                        prop: "approveStats",
                        type: 'function',
                        align: 'left',
                        callBack(row) {
                            let name = row.currentPersonName ?? ''
                            if (row.approveStats === '0') {
                                return `<div class="ellipsis">${name}未开始</div>`
                            } else if (row.approveStats === '1') {
                                return `<div style="color:#3c7fff" class="ellipsis">${name}审批中</div>`
                            } else if (row.approveStats === '2') {
                                return `<div style="color:#46cc95">审批通过</div>`
                            } else if (row.approveStats === '3') {
                                return `<div style="color:#f56c6c">审批拒绝</div>`
                            } else if (row.approveStats === '4') {
                                return `<div style="color:#f5b942">已撤销</div>`
                            } else if (row.approveStats === '5') {
                                return `<div style="color:#46cc95">审批通过(撤销拒绝)</div>`
                            } else if (row.approveStats === '6') {
                                return `<div style="color:#f56c6c">审批拒绝</div>`
                            }
                        }
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: 'operation',
                        labelWidth: '100'
                    }
                ],
                check: false,
                height: ''
            },
            table_data: [],
            total: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: '',
                queryState: '1',
                startTime: '',
                endTime: '',
                dataTitle: '',
                approveStats: ''
            },
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        })
    },
    created() {
        if (this.$route.query.activeName) {
            this.listQuery.queryState = this.$route.query.activeName
        }
        this.listQuery.schoolId = this.schoolId
        this.tabClick('1')
    },
    mounted() {

    },
    methods: {
        ...mapMutations(['setOAQueryData', 'setDealtNum']),
        tabClick(name) {
            this.listQuery.queryState = name
            if (this.listQuery.queryState === '1') {
                this.formData.data = this.formData.data.slice(0, 2)
            } else {
                this.formData.data = this.$options.data().formData.data
            }
            this.getList()
        },
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            const campusOAModel = new CampusOAModel();
            campusOAModel.getSchoolProcessApplyList(this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    this.table_data = res.data.data.list
                    this.total = res.data.data.totalCount
                    if (this.listQuery.queryState === '1'){
                        this.setDealtNum(this.total)
                    }
                })
            })
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case 'primary': // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    })
                    if (this.listQuery.time && this.listQuery.time.length > 0) {
                        this.listQuery.startTime = this.listQuery.time[0] + ' 00:00:00'
                        this.listQuery.endTime = this.listQuery.time[1] + ' 23:59:59'
                    } else {
                        this.listQuery.startTime = ''
                        this.listQuery.endTime = ''
                    }
                    this.getList(1);
                    break;
                case 'reset': // 重置
                    this.formData.data.forEach((item) => {
                        if (item.key !== 'queryState') {
                            item.value = '';
                            this.listQuery[item.key] = ''
                        }
                    })
                    this.listQuery.startTime = ''
                    this.listQuery.endTime = ''
                    this.getList(1);
                    break;
            }
        },
        formDetail(data) {
            this.setOAQueryData({
                id: data.id, // 申请记录id
                dataTitle: data.dataTitle, // 申请title
                backData: {
                    name: this.$route.name,
                    query: {
                        activeName: this.listQuery.queryState
                    }
                },
            })
            this.$router.push({
                name: 'CoworkingCampusOAOAApprovalApplyDetail'
            })
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.tabs-list {
    margin-top: 10px;
    display: flex;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow: hidden;

    .tabs-item {
        width: 100px;
        position: relative;
        text-align: center;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
    }

    .tabs-item.current {
        color: #3c7fff;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            height: 3px;
            width: 30px;
            background: #3C7FFF;
            border-radius: 2px;
        }
    }
}
</style>
