<!-- 首页-常用功能 -->
<template>
    <hm-card v-loading="loading" class="home-commonLy-used">
        <hm-title title="常用功能"></hm-title>
        <template v-if="usedMenuList.length > 0">
            <div
                class="add-btn"
                @click="handleAdd"
            >
                <i class="el-icon-plus"></i>
                <div>添加</div>
            </div>
            <el-scrollbar
                v-if="usedMenuList.length > 7"
                wrap-style="overflow-x:hidden;"
                style="height: 105px"
            >
                <div class="menu-wrap">
                    <hm-menu-item
                        v-for="item in usedMenuList"
                        :data="item"
                        :key="item.menuId"
                    />
                </div>
            </el-scrollbar>
            <div v-else class="menu-wrap">
                <hm-menu-item
                    v-for="item in usedMenuList"
                    :data="item"
                    :key="item.menuId"
                />
            </div>
        </template>
        <div
            v-else-if="!loading"
            class="add-btn1"
            @click="handleAdd"
        >
            <i class="el-icon-plus"></i>
            <div class="add-btn1-text">添加常用</div>
        </div>
        <dialog-wrapper
            :dialogObj="dialogObj"
            @handleClose="handleClose"
        >
            <dialog-data
                :loading="btnLoading"
                :all-menu-list="allMenuList"
                :used-menu-list="usedMenuList"
                :key="dialogObj.key"
                @submit="handleSubmit"
                @close="handleClose"
            ></dialog-data>
        </dialog-wrapper>
    </hm-card>
</template>

<script>
import { DialogWrapper } from "common-local";
import HmCard from "./Sub/hm-card.vue";
import HmTitle from "./Sub/hm-title.vue";
import HmMenuItem from "./Sub/hm-menu-item.vue";
import DialogData from "./HomeCommonlyUsed/DialogData.vue";
import {treeFind} from "@/libs/utils";

export default {
    name: "HomeCommonLyUsed",
    components: {
        DialogData,
        DialogWrapper,
        HmMenuItem,
        HmTitle,
        HmCard,
    },
    data() {
        return {
            loading: true,
            btnLoading: false,
            usedMenuList: [],
            allMenuList: [],
            dialogObj: {
                title: "自定义应用",
                dialogVisible: false,
                width: "758px",
                key: +new Date()
            },
        };
    },
    computed: {},
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getList()
        },
        getList() {
            this.loading = true
            this._fet('/school/schoolUserCommon/getListByUser', {
                plat: '1'
            }).then((res) => {
                if (res.data.code === '200') {
                    this.usedMenuList = res.data.data
                }
            }).finally(() => {
                this.loading = false
            })
        },
        async handleAdd() {
            if (this.allMenuList.length === 0) {
                await this.getAllMenuList();
            }
            this.dialogObj.dialogVisible = true;
            this.dialogObj.key += 1
        },
        async getAllMenuList() {
            await this._fet("/school/schoolUserCommon/getAllAuthMenuBuUser").then((res) => {
                if (res.data.code === "200" && res.data.data.length > 0) {
                    res.data.data.forEach((item) => {
                        item.children.forEach((it) => {
                            // 不存在子菜单显示当前菜单
                            if (it.children && it.children.length === 0) {
                                let data = this._.cloneDeep(it);
                                delete it.children;
                                it.children = [data];
                            }
                        });
                    });
                    this.allMenuList = res.data.data;
                }
            })
        },
        handleSubmit(data) {
            this.btnLoading = true
            let list = data.map((item) => ({
                menuId: item.menuId,
                path: item.path,
                menuName: item.title || item.menuName,
                title: item.title,
                name: item.name,
                isFrame: item.isFrame,
                isNeedSso: item.isNeedSso,
                ancestors: item.ancestors,
                parentId: item.parentId,
                frameUrl: item.frameUrl
            }))
            list.forEach((item) => {
                if (!item.frameUrl && item.isFrame == 1) {
                    let find = treeFind(this.allMenuList,x => x.menuId === item.menuId, 'children')
                    if (find && find.frameUrl) {
                        item.frameUrl = find.frameUrl
                    }
                }
            })
            this._fet('/school/schoolUserCommon/saveList', {
                schoolId: this.$store.state.schoolId,
                list: list,
                plat: '1',
            }).then((res) => {
                if (res.data.code === '200') {
                    this.handleClose()
                    this.$message.success('保存成功')
                    this.getList()
                } else {
                    this.$message.error(res.data.msg)
                }
            }).finally(() => {
                this.btnLoading = false
            })
        },
        handleClose() {
            this.dialogObj.dialogVisible = false;
        },
    },
};
</script>

<style scoped lang="scss">
.home-commonLy-used {
    position: relative;
    height: 138px;
    background: -webkit-linear-gradient(top, transparent 17px, #fbfbfb 0),
    -webkit-linear-gradient(left, transparent 17px, #fbfbfb 0);
    background-size: 19px 19px;
    background-color: #ffffff;
    padding: 0;


    .hm-title {
        padding: 10px 20px 9px;
        background: #F1F1F1;
    }

    .add-btn {
        cursor: pointer;
        position: absolute;
        right: 22px;
        top: 9px;
        display: flex;
        align-items: center;
        padding: 0;
        color: #4264f3;
        font-size: 14px;
        height: 18px;

        .el-icon-plus {
            font-size: 14px;
            font-weight: bold;
            margin-right: 4px;
        }

        div {
            line-height: 18px;
        }
    }

    .add-btn1 {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-start;
        margin-left: 32px;
        margin-top: 21px;

        .el-icon-plus {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 46px;
            background: #ffffff;
            border: 1px dashed #9d9d9d;
            border-radius: 10px;
            font-size: 18px;
            font-weight: bold;
            color: #808080;
            margin-bottom: 10px;
        }

        .add-btn1-text {
            font-size: 14px;
            color: #333333;
            line-height: 12px;
        }

        &:hover {
            .el-icon-plus {
                color: #2166ee;
                border-color: #2166ee;
            }
        }
    }

    .menu-wrap {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 7px 20px 0;
    }
}

::v-deep .el-dialog {
    &__body {
        padding: 13px 16px 16px;
    }
}

::v-deep .el-scrollbar__wrap {
    height: 100% !important;
}
</style>
